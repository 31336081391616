<template lang="pug">
    aside#rotacionarAparelho(:class="{ativo}")
        include ../../assets/images/svgs/icon_rotate.svg
        h2 Por favor, utilize seu aparelho em modo retrato para uma melhor experiência!
</template>

<script>
export default {
    data() {
        return {
            ativo: window.innerHeight < window.innerWidth
        }
    },
    created() {
        setTimeout(() => {
            window.addEventListener('resize', this.matchMedia)
        }, 100)
    },
    methods: {
        matchMedia() {
            setTimeout(() => {
                this.ativo = window.innerHeight < window.innerWidth
            }, 350)
        }
    },
}
</script>

<style lang="stylus" scoped src="./RotacionarAparelho.styl"></style>