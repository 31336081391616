import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	mode: 'history',
    base: process.env.VUE_APP_PUBLICPATH,
    // eslint-disable-next-line
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
	routes: [
        {
            path: '/',
            name: 'home',
            component: () => import( /* webpackChunkName: "home" */ '@/views/Home/Home.vue')
        }, 
        {
            path: '/partners',
            name: 'partners',
            component: () => import( /* webpackChunkName: "partners" */ '@/views/Partners/Partners.vue')
        },
        {
            path: '/business',
            name: 'business',
            component: () => import( /* webpackChunkName: "business" */ '@/views/Business/Business.vue')
        },
        {
            path: '/yachthouse',
            name: 'yachthouse',
            component: () => import( /* webpackChunkName: "yachthouse" */ '@/views/Yachthouse/Yachthouse.vue')
        },
        {
            path: '/contato',
            name: 'contato',
            component: () => import( /* webpackChunkName: "contato" */ '@/views/Contato/Contato.vue')
        },
        {
            path: '/sobre',
            name: 'sobre',
            component: () => import( /* webpackChunkName: "sobre" */ '@/views/Sobre/Sobre.vue')
        },
        {
            path: '/sobre/cards',
            name: 'cards',
            props: true,
            component: () => import( /* webpackChunkName: "cards" */ '@/views/Cards/Cards.vue'),
            beforeEnter: (to, from, next) => {
                if (to.params.lista && typeof to.params.ativo !== 'undefined')
                    return next();
                return next({ name: 'sobre', replace: true });
            }
        },
        {
			path: '/blog',
			name: 'blog',
			component: () => import( /* webpackChunkName: "posts" */ '@/views/Posts/Posts.vue')
		},
		{
			path: '/blog/:slug',
			name: 'post-blog',
			component: () => import( /* webpackChunkName: "post" */ '@/views/Post/Post.vue')
		},
		{
			path: '/imprensa',
			name: 'imprensa',
			component: () => import( /* webpackChunkName: "posts" */ '@/views/Posts/Posts.vue')
		},
		{
			path: '/imprensa/:slug',
			name: 'post-imprensa',
			component: () => import( /* webpackChunkName: "post" */ '@/views/Post/Post.vue')
		},
        {
			path: '/funcionario/:slug',
			name: 'card-funcionario',
			component: () => import( /* webpackChunkName: "cardFuncionario" */ '@/views/CardFuncionario/CardFuncionario.vue')
		},
    ]
})