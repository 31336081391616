import './assets/stylus/main.styl'

import Vue from 'vue'
import Axios from 'axios';
import Vue2TouchEvents from 'vue2-touch-events';
import VueScrollMagic from 'vue-scrollmagic'
import App from './App.vue'
import router from './routes'
import Devinho from '@/libs/devinho'

Vue.config.productionTip = false;

const base_tag = document.querySelector('meta[name="base"]')
Vue.prototype.$axios = Axios.create({
	baseURL: `${base_tag ? base_tag.getAttribute('content') : ''}/api`
})

Vue.use(Devinho);
Vue.use(Vue2TouchEvents);
Vue.use(VueScrollMagic, {
	vertical: false
});

// if(!Devinho.isMobile())
// 	window.location.href = ""

if ('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual';
}

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
