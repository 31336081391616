<template lang="pug">
    nav#mainMenu(:class="{open, closed: !open}")
        ul
            li
                router-link(:to="{name: 'sobre'}") Sobre
            li
                router-link(:to="{name: 'partners'}") GT. Partners
            li
                router-link(:to="{name: 'business'}") GT. Business
            li
                router-link(:to="{name: 'blog'}") Blog
            li
                router-link(:to="{name: 'imprensa'}") Imprensa
            li
                router-link(:to="{name: 'contato'}") Contato

        button(type="button", @click="toggleMenu()")
            include ../../assets/images/svgs/icon_close.svg
            | Menu
</template>

<script>
export default {
    props: {
        open: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        toggleMenu() {
            this.$emit('update:open', !this.open);
        }
    },
}
</script>

<style lang="stylus" scoped src="./Menu.styl"></style>