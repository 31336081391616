<template lang="pug">
    header#mainHeader
        div.wrapper.flex
            div.botao
                button.back(@click="goBack()" v-show="$route.name != 'home'")
                    include ../../assets/images/svgs/icon_arrow_back.svg
            figure.logo
                router-link(:to="{name: 'home'}")
                    h1 GT Company
                    img(:src="require('@/assets/images/logo/logo_gtcompany.png')", alt="GT Company")
            // ul.redes
                li.facebook
                    a
                        include ../../assets/images/svgs/icon_facebook.svg
                li.twitter
                    a
                        include ../../assets/images/svgs/icon_twitter.svg
                li.linkedin
                    a
                        include ../../assets/images/svgs/icon_linkedin.svg
</template>

<script>
export default {
    methods: {
        goBack() {
            if (this.$route.name != 'home')
                this.$router.go(-1);
        }
    },
};
</script>

<style lang="stylus" scoped src="./Header.styl"></style>
