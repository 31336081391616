<template lang="pug">
	main#app(@click="closesMenu")
		Header(v-if="$route.name != 'card-funcionario'")
		Menu(:open.sync="openMenu", ref="menu" v-if="$route.name != 'card-funcionario'")
		router-view
		RotacionarAparelho
</template>

<script>
import Header from '@/components/Header/Header.vue';
import Menu from '@/components/Menu/Menu.vue';
import RotacionarAparelho from '@/components/RotacionarAparelho/RotacionarAparelho.vue';

export default {
	components: {
		Header,
		Menu,
		RotacionarAparelho
	},
	data() {
		return {
			openMenu: false
		}
	},
	watch: {
		'$route': function() {
			if(this.openMenu)
				this.openMenu = false;
		}
	},
	methods: {
		closesMenu($event) {
			if(this.openMenu && !$event.target.closest("#mainMenu"))
				this.openMenu = false;
		}
	},
};
</script>

<style lang="stylus" scoped src="./assets/stylus/App.styl"></style>
